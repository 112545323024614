import config, { getIsMaintenanceActive } from "~/config";

export default defineNuxtRouteMiddleware(async (to) => {
  if (getIsMaintenanceActive()) {
    return navigateTo(config.maintenancePage);
  }

  if (process.client) {
    const authStore = useAuthStore();
    const adminImpStore = useAdminImpersonificationStore();
    const { $auth } = useNuxtApp();

    if (!adminImpStore.customer && !authStore.customer.loggedIn) {
      await $auth.signIn(to.fullPath);
    }
  }
});
